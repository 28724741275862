<template>
  <div>
    <!--SNACKBAR-->
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <v-alert
            style="z-index: 999999999"
            v-model="NOTIFICATION_STATUS"
            :color="NOTIFICATION_COLOR"
            elevation="24"
            type="success"
            dismissible
        >{{ NOTIFICATION }}
        </v-alert>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-container fluid>

      <!--MEETING-->
      <iframe
          v-if="streamToken"
          :src="streamUrlFront + '/meeting/ltr?token=' + streamToken"
          width="100%"
          height="770px"
          allow="camera; microphone; fullscreen; display-capture"
      ></iframe>
      <v-btn v-if="streamToken" @click="streamToken = null; getClasses()" color="#f27e42" rounded small
             style="color: white">
        End Meeting
      </v-btn>

      <!--CLASSES MANAGEMENT-->
      <v-row v-if="!streamToken">
        <v-col cols="12" v-for="clas in classes" :key="clas.id">
          <v-card style="border-radius: 25px; padding: 10px">
            <!--CLASS-->
            <v-card-title>
              Class : {{ clas.title }}

              <!--HOME WORK BTN-->
              <v-btn rounded color="#579e81" @click="openHomeworksModal(clas)"
                     style="position: absolute; right: 20px; color: white">
                <span v-if="clas.broadcaster.id == user.data.vod_user_id">Assign Homework</span>
                <span v-else>See Homeworks</span>
              </v-btn>
            </v-card-title>

            <!--MEETINGS TABLE-->
            <v-data-table
                :headers="meetingHeaders"
                :items="clas.meetings"
                :items-per-page="15"
                class="elevation-1"
            >
              <template v-slot:item.clas>
                {{ clas.title }}
              </template>
              <template v-slot:item.is_ended="{item}">
                <v-icon small v-if="item.is_ended" color="green">mdi-check</v-icon>
                <v-icon small v-else color="red">mdi-close</v-icon>
              </template>
              <template v-slot:item.actions="{item}">

                <!--JOIN BTN-->
                <v-btn class="white--text" small color="#579e81" @click="joinMeeting(item)" :disabled="!item.can_join">
                  Join
                </v-btn>

                <!--QUESTIONS BTN-->
                <v-btn v-if="clas.broadcaster.id == user.data.vod_user_id" class="white--text ml-3" small
                       color="#f27e42" @click="openQuestionsModal(item, clas)">
                  Questions
                </v-btn>

                <!--SCORES BTN-->
                <v-btn v-if="clas.broadcaster.id == user.data.vod_user_id" class="white--text ml-3" small
                       color="#f27e42" @click="openScoresModal(item, clas)">
                  Scores
                </v-btn>

              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!--DIALOG COMPONENTS-->
    <meeting-homework :homeworks-dialog="homeworksDialog"
                      :selected-clas="selectedClas"
                      :users="users"
                      :tasks="tasks"
                      v-on:close-dialog="closeHomeworksModal"
                      v-on:get-tasks="getTasks(selectedClas)">
    </meeting-homework>
    <meeting-exam :questions-dialog="questionsDialog"
                  :selected-meeting="selectedMeeting"
                  :questions="questions"
                  v-on:close-dialog="closeQuestionsModal"
                  v-on:get-questions="getQuestions(selectedMeeting)">
    </meeting-exam>
    <meeting-score :scores-dialog="scoresDialog"
                   :selected-meeting="selectedMeeting"
                   :scores="scores"
                   v-on:close-dialog="closeScoresModal"
                   v-on:get-questions="getScores(selectedMeeting)">
    </meeting-score>
  </div>
</template>

<script>
import axios from "axios";
import MeetingHomework from "../components/MeetingHomework";
import MeetingExam from "../components/MeetingExam";
import MeetingScore from "../components/MeetingScores";

export default {
  name: "Classes",
  components: {MeetingExam, MeetingHomework, MeetingScore},
  data() {
    return {
      classes: [],
      users: [],
      tasks: [],
      questions: [],
      scores: [],

      meetingHeaders: [
        {text: 'Class', value: 'clas'},
        {text: 'Schedule Start Time', value: 'schedule_start_time'},
        {text: 'Schedule End Time', value: 'schedule_end_time'},
        {text: 'Is Ended', value: 'is_ended'},
        {text: 'Actions', value: 'actions'},
      ],

      streamToken: null,
      streamUrlBack: process.env.VUE_APP_STREAM_URL_BACK,
      streamUrlFront: process.env.VUE_APP_STREAM_URL_FRONT,
      vodToken: process.env.VUE_APP_VOD_TOKEN,

      homeworksDialog: false,
      questionsDialog: false,
      scoresDialog: false,

      selectedClas: null,
      selectedMeeting: null,
      user: {
        data: null
      },
    }
  },
  mounted() {
    this.user.data = this.$store.state.user
    this.getClasses()
  },

  methods: {
    joinMeeting(meeting) {
      axios.defaults.baseURL = this.streamUrlBack;
      axios.defaults.headers.post['Content-Type'] = 'application/json';

      axios
          .post('/api/web-rtc/get-stream-token', {
            token: this.vodToken,
            meeting_id: meeting.id,
            user_id: this.user.data.vod_user_id,
          })
          .then((res) => {
            this.streamToken = res.data.data.token
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },

    /* GET DATA FUNCTIONS */
    getClasses() {
      window.axios.get('/api/classes/index')
          .then((res) => {
            this.classes = res.data.data
            console.log(this.classes)
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },
    getUsers(clas) {
      window.axios.get('/api/classes/users/' + clas.id)
          .then((res) => {
            this.users = res.data.data
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },
    getTasks(clas) {
      if (clas.broadcaster && clas.broadcaster.id && clas.broadcaster.id == this.user.data.vod_user_id) {
        window.axios.get('/api/classes/tasks/index/' + clas.id)
            .then((res) => {
              this.tasks = res.data.data
            })
            .catch((err) => {
              this.NOTIFICATION_STATUS = true
              this.NOTIFICATION_COLOR = 'red'
              this.NOTIFICATION = err.response.data.message
            })
      } else {
        window.axios.get('/api/classes/tasks/index/' + clas.id + '?my-user')
            .then((res) => {
              this.tasks = res.data.data
            })
            .catch((err) => {
              this.NOTIFICATION_STATUS = true
              this.NOTIFICATION_COLOR = 'red'
              this.NOTIFICATION = err.response.data.message
            })
      }
    },
    getQuestions(meeting) {
      window.axios.get('/api/classes/meeting/questions/index/' + meeting.id)
          .then((res) => {
            this.questions = res.data.data
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },
    getScores(meeting) {
      window.axios.get('/api/classes/meeting/scores/' + meeting.id)
          .then((res) => {
            this.scores = res.data.data
          })
          .catch((err) => {
            this.NOTIFICATION_STATUS = true
            this.NOTIFICATION_COLOR = 'red'
            this.NOTIFICATION = err.response.data.message
          })
    },

    /* OPEN MODALS */
    openHomeworksModal(clas) {
      this.selectedClas = clas
      this.homeworksDialog = true
      this.getTasks(clas)
      this.getUsers(clas)
    },
    openQuestionsModal(meeting, clas) {
      this.selectedMeeting = meeting
      this.selectedMeeting.clas = clas
      this.questionsDialog = true
      this.getQuestions(meeting)
    },
    openScoresModal(meeting, clas) {
      this.selectedMeeting = meeting
      this.selectedMeeting.clas = clas
      this.scoresDialog = true
      this.getScores(meeting)
    },

    /* CLOSE MODALS */
    closeHomeworksModal() {
      this.selectedClas = null
      this.homeworksDialog = false
    },
    closeQuestionsModal() {
      this.selectedMeeting = null
      this.questionsDialog = false
    },
    closeScoresModal() {
      this.selectedMeeting = null
      this.scoresDialog = false
    }
  },
}
</script>

<style scoped>
.end-meeting-class {
  position: absolute;
  top: 150px;
  left: 135px;
}

.v-btn {
  box-shadow: none !important;
}
</style>